@import "../../mixins.scss";

.cardContainer {
  display: flex;
  background: #fff;
  gap: 8px;
  margin-bottom: 24px;

  @include for-tablet-portrait-down {
    flex-direction: column;
  }

  img {
    height: 230px;
    width: 332px;
    border-radius: 12px;
    opacity: 0.9;
    background: lightgray 50% / cover no-repeat;
    object-fit: cover;
    margin-bottom: 24px;
    aspect-ratio: 16/9;
    max-width: 332px;

    @include for-tablet-portrait-down {
      max-width: 100%;
      width: 100%;
    }
  }

  .infoBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;

    h4 {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }

    .text {
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      opacity: 0.6;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
    }

    .date {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      border-radius: 100px;
      border: 1px solid var(--color-001, #000);
      padding: 0 10px;
      max-width: fit-content;
    }

    a {
      overflow: hidden;
      color: var(--color-007, #00b8f0);
      text-overflow: ellipsis;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-thickness: auto;
      text-underline-offset: auto;
      text-underline-position: from-font;
    }
  }
}
