@mixin for-mobile-only {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin for-desktop-only {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin for-phone-only {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin for-tablet-portrait-down {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-wide-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-wide-desktop-only {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin retina {
  @media (min-resolution: 2dppx) {
    @content;
  }
}
