
@font-face {
  font-family: 'TT Autonomous Trl';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/TT Autonomous Trial Regular.woff');
  }

body {
  background: #fff;
  font-family: TT Autonomous Trl;
}

p, h4, h1 {
  padding: 0;
  margin: 0;
}