.mainBlock {
  display: flex;
  justify-content: center;
  border-radius: 25px;
  background: #fff;
  //box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);

  h1 {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin: 40px 0 22px;
  }

  .innerBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 92%;
    background: #fff;
  }
}

.loaderBox {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.noRecordsImg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.eventsBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  overflow: hidden;
  gap: 24px;

  div {
    width: 100%;
  }
}

.emptyBox {
  display: flex;
  flex-direction: column;
  gap: 121px;

  img {
    width: 354.726px;
    height: 248px;
  }
}

.emptyAnnounce {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin: 40px 0 24px;
}

.announceInfo {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #666;
}

.imageContainer {
  display: flex;
  justify-content: center;
}
