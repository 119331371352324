@import "../../mixins.scss";

.container {
  display: flex;
  flex-direction: column;
}

.innerBox {
  margin: 24px;
}

.breadcrumbs {
  margin-bottom: 24px;
}

.announce {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: 0.21px;
  color: #666;
  text-decoration: none;
}

.name {
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: 0.21px;
}

.title {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0;
  margin: 24px 0 12px;
  word-wrap: break-word;
}

.image {
  margin-bottom: 20px;
  object-fit: contain;
  max-width: -webkit-fill-available;
  border-radius: 12px;
  width: 100%;
  min-height: 290px;
  opacity: 0.9;
  background: lightgray 50% / cover no-repeat;
  object-fit: cover;
}

.date {
  padding: 3px 4px;
  border-radius: 100px;
  border: 1px solid var(--color-001, #000);
  width: fit-content;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin-bottom: 12px;
}

.text {
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.6;
  word-wrap: break-word;
}

.loaderBox {
  display: flex;
  justify-content: center;
}
